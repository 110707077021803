import React from 'react';
import styled from '@emotion/styled';

import FunnelButton from '../../Button';

import FallbackImage from 'assets/others/hero_video_fallback_mobile.jpg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING } from 'utils/constants';
import Video from 'assets/videos/hero_video_mobile.mp4';

const Container = styled.div((props) => ({
  display: 'none',
  [BREAKPOINTS[800]]: {
    backgroundColor: props.theme.colors.lightSand,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Title = styled.div((props) => ({
  fontFamily: 'Taviraj',
  fontSize: 30,
  fontWeight: 700,
  lineHeight: '44px',
  letterSpacing: 2,
  color: props.theme.colors.backgroundDark,
  textAlign: 'center',
  marginTop: 24,
}));

const Subtitle = styled.div((props) => ({
  fontSize: 16,
  letterSpacing: '0.01em',
  fontWeight: 100,
  lineHeight: '28px',
  color: props.theme.colors.backgroundDark,
  opacity: 0.9,
  margin: '16px 0 26px 0',
  textAlign: 'center',
}));

const StyledVideo = styled.video({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  zIndex: 1,
});

const VideoOverlay = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(23, 31, 35, 0.4)',
  zIndex: 2,
});

const VideoContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: 540,
  overflow: 'hidden',
});

const ContentContainer = styled.div({
  padding: `0 ${MOBILE_SIDE_PADDING}`,
  display: 'flex',
  alignSelf: 'stretch',
  flexDirection: 'column',
});

const StyledFunnelButton = styled(FunnelButton)((props) => ({
  backgroundColor: props.theme.colors.backgroundDark,
  color: props.theme.colors.white,
  maxWidth: '100%',
  width: '100%',
  ':hover': {
    backgroundColor: '#1C2428',
  },
}));

const StyledVideoFallback = styled.img({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
});

const MobileHero: React.FC = () => (
  <Container>
    <VideoContainer>
      <StyledVideo autoPlay muted loop id="hero_video" playsInline>
        <source src={Video} type="video/mp4" />
      </StyledVideo>
      <StyledVideoFallback src={FallbackImage} />
      <VideoOverlay />
    </VideoContainer>
    <ContentContainer>
      <Title>The choice is Yours</Title>
      <Subtitle>
        Rejuvenate your body, mind and soul with musical soundscapes, meditations, sleep stories
        plus advice from our team of expert psychologists
      </Subtitle>
      <StyledFunnelButton />
    </ContentContainer>
  </Container>
);

export default MobileHero;
