import React from 'react';
import styled from '@emotion/styled';

interface ICategoryContentPlayer {
  video?: string;
  onCancelView: () => void;
}

const Overlay = styled.div((props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: props.theme.colors.backgroundDark,
  opacity: 0.5,
  zIndex: 100,
}));

const StyledVideo = styled.video({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 700,
  maxWidth: '100%',
  zIndex: 101,
});

const CategoryContentPlayer: React.FC<ICategoryContentPlayer> = ({ video, onCancelView }) => {
  if (!video) {
    return null;
  }

  return (
    <>
      <Overlay onClick={onCancelView} />
      <StyledVideo autoPlay id="hero_video" onEnded={onCancelView} playsInline controls>
        <source src={video} type="video/mp4" />
      </StyledVideo>
    </>
  );
};

export default CategoryContentPlayer;
