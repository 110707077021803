import React from 'react';
import styled from '@emotion/styled';

import PlayIcon from 'assets/svgs/play.svg';
import { BREAKPOINTS } from 'utils/constants';

interface IContentPlayButton {
  onClick: () => void;
}

const Button = styled.button({
  position: 'absolute',
  width: 92,
  height: 92,
  borderRadius: 46,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(255, 249, 242, 0.6)',
  opacity: 0,
  transition: 'opacity 0.9s ease-out',
  cursor: 'pointer',
  outline: 'none',
  background: 'none',
  [BREAKPOINTS[800]]: {
    opacity: 1,
  },
});

const ContentPlayButton: React.FC<IContentPlayButton> = ({ onClick }) => (
  <Button onClick={onClick}>
    <PlayIcon />
  </Button>
);

export default ContentPlayButton;
