import React from 'react';

import DesktopHero from './DesktopHero';
import MobileHero from './MobileHero';

const Hero: React.FC = () => (
  <>
    <DesktopHero />
    <MobileHero />
  </>
);

export default Hero;
