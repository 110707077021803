import React from 'react';
import styled from '@emotion/styled';

import ContentPlayButton from './ContentPlayButton';
import { CATEGORIES } from './Categories';

import { BREAKPOINTS } from 'utils/constants';

interface IContainerProps {
  image: string;
}

interface IContentCategoryProps {
  title: CATEGORIES;
  subtitle?: string;
  onPlayClick: (value: CATEGORIES) => void;
  isOverlayVisible?: boolean;
}

type TContentCategory = IContentCategoryProps & IContainerProps;

const Container = styled.div<IContainerProps>((props) => ({
  position: 'relative',
  backgroundImage: `url(${props.image})`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: 550,
  flexBasis: 'calc(50% - 4px)',
  margin: 2,
  '@media (hover: hover) and (pointer: fine)': {
    ':hover span': {
      opacity: 0.5,
      transition: 'opacity 0.9s ease-out',
    },
    ':hover button': {
      opacity: 1,
      transition: 'opacity 0.9s ease-out',
    },
  },
  [BREAKPOINTS[800]]: {
    flexBasis: '100%',
    margin: 0,
    height: 460,
  },
}));

const Title = styled.div((props) => ({
  marginTop: 32,
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 32,
  lineHeight: '54px',
  color: props.theme.colors.lightSand,
  textAlign: 'center',
}));

const Subtitle = styled.div((props) => ({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  color: props.theme.colors.lightSand,
  textAlign: 'center',
}));

const Overlay = styled.span((props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: props.theme.colors.backgroundDark,
  opacity: 0,
  transition: 'opacity 0.9s ease-out',
  [BREAKPOINTS[800]]: {
    opacity: 0.2,
  },
}));

const ContentCategory: React.FC<TContentCategory> = ({
  title,
  subtitle,
  image,
  onPlayClick,
  isOverlayVisible = true,
  ...rest
}) => (
  <Container image={image} {...rest}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {isOverlayVisible && (
      <>
        <Overlay />
        <ContentPlayButton onClick={() => onPlayClick(title)} />
      </>
    )}
  </Container>
);

export default ContentCategory;
