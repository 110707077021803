import React from 'react';
import styled from '@emotion/styled';

import FunnelButton from '../../Button';

import FallbackImage from 'assets/others/hero_video_fallback_desktop.jpg';
import Video from 'assets/videos/hero_video_web.mp4';
import { BREAKPOINTS, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div({
  height: 740,
  position: 'relative',
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: `0 ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[1320]]: {
    backgroundSize: 'auto 100%',
    backgroundAttachment: 'unset',
  },
  [BREAKPOINTS[800]]: {
    display: 'none',
  },
});

const Title = styled.div((props) => ({
  fontFamily: 'Taviraj',
  fontSize: 70,
  fontWeight: 700,
  lineHeight: '86px',
  letterSpacing: 2,
  color: props.theme.colors.lightSand,
  maxWidth: 595,
  zIndex: 3,
}));

const Subtitle = styled.div((props) => ({
  fontSize: 16,
  letterSpacing: '0.01em',
  fontWeight: 100,
  lineHeight: '28px',
  maxWidth: 595,
  color: props.theme.colors.lightSand,
  opacity: 0.9,
  margin: '18px 0 38px 0',
  zIndex: 3,
}));

const StyledVideo = styled.video({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
  zIndex: 1,
});

const VideoOverlay = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(23, 31, 35, 0.4)',
  zIndex: 2,
});

const StyledFunnelButton = styled(FunnelButton)({
  zIndex: 3,
});

const StyledVideoFallback = styled.img({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  minHeight: '100%',
  objectFit: 'cover',
});

const Hero: React.FC = () => (
  <Container>
    <Title>The choice is Yours</Title>
    <Subtitle>
      Rejuvenate your body, mind and soul with musical soundscapes, meditations, sleep stories plus
      advice from our team of expert psychologists
    </Subtitle>
    <StyledFunnelButton />
    <StyledVideo autoPlay muted loop id="hero_video" playsInline>
      <source src={Video} type="video/mp4" />
      <StyledVideoFallback src={FallbackImage} />
    </StyledVideo>
    <VideoOverlay />
  </Container>
);

export default Hero;
