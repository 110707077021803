import React from 'react';
import styled from '@emotion/styled';

import ContentPlayButton from './ContentPlayButton';
import { CATEGORIES } from './Categories';

import { BREAKPOINTS } from 'utils/constants';

interface IMobileCategory {
  title: CATEGORIES;
  subtitle: string;
  image: string;
  onPlayClick: (value: CATEGORIES) => void;
}

const Container = styled.div((props) => ({
  position: 'relative',
  backgroundColor: props.theme.colors.darkSand,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexBasis: 'calc(50% - 4px)',
  margin: 2,
  maxHeight: 400,
  ':hover span': {
    opacity: 0.5,
    transition: 'opacity 0.9s ease-out',
  },
  ':hover button': {
    opacity: 1,
    transition: 'opacity 0.9s ease-out',
  },
  [BREAKPOINTS[800]]: {
    flexBasis: '100%',
    margin: 0,
    height: 460,
  },
}));

const Title = styled.div((props) => ({
  fontFamily: 'Taviraj',
  color: props.theme.colors.backgroundDark,
  fontWeight: 700,
  fontSize: 32,
  marginTop: 20,
  lineHeight: '54px',
}));

const Subtitle = styled.div((props) => ({
  color: props.theme.colors.backgroundDark,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 20,
}));

const PhoneImage = styled.img({
  margin: 'auto 20px 0 20px',
  width: 280,
});

const Overlay = styled.span((props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: props.theme.colors.backgroundDark,
  opacity: 0,
  transition: 'opacity 0.9s ease-out',
  [BREAKPOINTS[800]]: {
    opacity: 0.2,
  },
}));

const MobileCategory: React.FC<IMobileCategory> = ({ title, subtitle, image, onPlayClick }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <PhoneImage src={image} />
    <Overlay />
    <ContentPlayButton onClick={() => onPlayClick(title)} />
  </Container>
);

export default MobileCategory;
