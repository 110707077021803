import React from 'react';

import DesktopScreenCategories from './DesktopScreenCategories';
import MobileScreenCategories from './MobileScreenCategories';
import { ICategoriesProps } from './Categories';

const CategoriesWrapper: React.FC<ICategoriesProps> = (props) => {
  return (
    <>
      <DesktopScreenCategories {...props} />
      <MobileScreenCategories {...props} />
    </>
  );
};

export default CategoriesWrapper;
