import React from 'react';
import styled from '@emotion/styled';

import MobileCategory from './MobileCategory';
import ContentCategory from './ContentCategory';
import { CATEGORIES, ICategoriesProps } from './Categories';

import { BREAKPOINTS } from '../../../utils/constants';

import SleepImage from 'assets/others/sleep.jpg';
import MusicImage from 'assets/others/music.jpg';
import PsychologyImage from 'assets/others/psychology.jpg';
import AdviceImage from 'assets/others/advice.jpg';
import BodyPhone from 'assets/others/body_phone.png';
import MeditationPhone from 'assets/others/meditation_phone.png';

const MobileWrapper = styled.div({ display: 'none', [BREAKPOINTS[800]]: { display: 'contents' } });

const MobileScreenCategories: React.FC<ICategoriesProps> = ({ onPlayClick }) => (
  <MobileWrapper>
    <ContentCategory
      title={CATEGORIES.SLEEP}
      subtitle="Prepare yourself for a good night’s rest"
      image={SleepImage}
      onPlayClick={onPlayClick}
    />
    <MobileCategory
      title={CATEGORIES.MEDITATION}
      subtitle="Calm your busy mind"
      image={MeditationPhone}
      onPlayClick={onPlayClick}
    />
    <ContentCategory
      title={CATEGORIES.MUSIC}
      subtitle="Lose yourself in relaxing soundscapes"
      image={MusicImage}
      onPlayClick={onPlayClick}
    />
    <ContentCategory
      title={CATEGORIES.PSYCHOLOGY}
      image={PsychologyImage}
      onPlayClick={onPlayClick}
    />
    <MobileCategory
      title={CATEGORIES.BODY}
      subtitle="Keep your body healthy and alert"
      image={BodyPhone}
      onPlayClick={onPlayClick}
    />
    <ContentCategory
      title={CATEGORIES.ADVICE}
      subtitle="Handy life tips from psychology experts"
      image={AdviceImage}
      onPlayClick={onPlayClick}
      isOverlayVisible={false}
    />
  </MobileWrapper>
);

export default MobileScreenCategories;
