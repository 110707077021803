import React, { useState } from 'react';
import styled from '@emotion/styled';

import CategoryContentPlayer from './CategoryContentPlayer';
import CategoriesWrapper from './CategoriesWrapper';

import sleep from 'assets/videos/sleep.mp4';
import body from 'assets/videos/body.mp4';
import meditation from 'assets/videos/meditation.mp4';
import music from 'assets/videos/music.mp4';
import psychology from 'assets/videos/psychology.mp4';
import MobilePhones from 'assets/others/mobile_phones_player.png';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.lightSand,
  padding: '80px 0 0',
  [BREAKPOINTS[800]]: {
    paddingTop: 56,
  },
}));

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  padding: `0 ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    padding: `0 ${MOBILE_SIDE_PADDING}`,
  },
});

const Title = styled.div((props) => ({
  color: props.theme.colors.backgroundDark,
  fontFamily: 'Taviraj',
  fontWeight: 700,
  fontSize: 45,
  lineHeight: '52px',
  marginBottom: 12,
  letterSpacing: '0.2px',
  textAlign: 'center',
  [BREAKPOINTS[800]]: {
    fontSize: 30,
    lineHeight: '44px',
  },
}));

const Subtitle = styled.div((props) => ({
  color: props.theme.colors.backgroundDark,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  [BREAKPOINTS[800]]: {
    fontSize: 18,
    lineHeight: '27px',
  },
}));

const ContentContainer = styled.div({
  marginTop: 70,
  display: 'flex',
  flexWrap: 'wrap',
  [BREAKPOINTS[800]]: {
    marginTop: 34,
  },
});

const MobilePhonesImage = styled.img({
  position: 'absolute',
  right: WEB_SIDE_PADDING,
  top: -460,
  width: 280,
  zIndex: 2,
  [BREAKPOINTS[1150]]: {
    display: 'none',
  },
});

export enum CATEGORIES {
  SLEEP = 'Sleep',
  BODY = 'Body',
  MUSIC = 'Music',
  PSYCHOLOGY = 'Psychology',
  ADVICE = 'Advice',
  MEDITATION = 'Meditation',
}

export const VIDEO_BY_CATEGORY = {
  [CATEGORIES.SLEEP]: sleep,
  [CATEGORIES.BODY]: body,
  [CATEGORIES.MUSIC]: music,
  [CATEGORIES.PSYCHOLOGY]: psychology,
  [CATEGORIES.ADVICE]: '',
  [CATEGORIES.MEDITATION]: meditation,
};

export interface ICategoriesProps {
  onPlayClick: (value: CATEGORIES) => void;
}

const Categories: React.FC = () => {
  const [video, setVideo] = useState<string>('');

  const handlePlayClick = (category: CATEGORIES) => {
    setVideo(VIDEO_BY_CATEGORY[category]);
    document.body.style.overflow = 'hidden';
  };

  const onExitVideo = () => {
    setVideo('');
    document.body.style.overflow = '';
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Over 300+ activities</Title>
        <Subtitle>
          Curate your perfect self-care routine with our best in class content recommendations
        </Subtitle>
        <MobilePhonesImage src={MobilePhones} />
      </TitleContainer>
      <ContentContainer>
        <CategoriesWrapper onPlayClick={handlePlayClick} />
      </ContentContainer>
      <CategoryContentPlayer onCancelView={onExitVideo} video={video} />
    </Container>
  );
};

export default Categories;
